import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { InternetFacility } from '../interfaces/InternetFacility';
import { Alert, Backdrop, Box, Button, Card, CardContent, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled, tableCellClasses } from '@mui/material';
import { HpsdConfigBodyRequest } from '../interfaces/HpsdConfigBodyRequest';
import { hpsdConfigCallbackRequest, hpsdConfigRequest } from '../services/apiSys';
import handleError from '../utils/handleError';
import { IPV4MaskInput, IpMaskMaskInput } from '../utils/MaskUtils';
import CircularProgressIndeterminateWithLabel from './CircularProgressIndeterminateWithLabel';

type Props = {
  dataFacility: InternetFacility;
};

type IpRow = {
  id: number;
  bloco: string;
  ip: string | undefined;
  status: string;
  mascara: string | undefined;
  newMascara?: string;
};

const motives = [
  'IP Duplicado',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#333333',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.background.paper,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ManobraIpDisplay = ({ dataFacility }: Props) => {
  const [ipsSelected, setIpsSelected] = React.useState<readonly number[]>([]);
  const [ipTableRows, setIpTableRows] = useState<IpRow[]>([]);
  const [motive, setMotive] = useState<string>('');
  const [resultId, setResultId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [warning, setWarning] = useState<string>('');
  const [error, setError] = useState<string>('');
  const notificationRef = useRef<HTMLDivElement>(null);

  const isSelected = (id: number) => ipsSelected.indexOf(id) !== -1;

  const geralCharacteristic = useCallback(() => {
    return dataFacility.serviceCharacteristic.find((char) => char.name === 'GERAL')?.value;
  }, [dataFacility])(); // Invoking the function immediately to get the initial values

  const executeManobra = useCallback(async (blocoIps: any) => {
    try {

      const body: HpsdConfigBodyRequest = {
        service: {
          id: dataFacility.id,
          category: "ManobraIPs.MNIP06",
          serviceSpecification: "Internet"
        },
        fields: [
          blocoIps,
          { name: "NaturezaOrdem", value: "Manobra" },
          { name: 'REALLOCATION_REASON', value: motive },
          { name: 'REALLOCATION_REASON_TEMP', value: motive },
        ]
      };
      console.log(body);
      const resp = await hpsdConfigRequest(body);
      setResultId(resp.data?.id_result);
      console.log('Manobra Iniciada');
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  }, [dataFacility, motive]);

  /**
   * Build IpTableRows
   */
  useEffect(() => {
    const ipsData = dataFacility.serviceCharacteristic.find((service) => service.name === "IPS")?.value;

    if (ipsData) {
      setIpTableRows([
        { id: 0, bloco: 'IPV4 LAN', ip: ipsData['b2bLanIPV4CPENetworkAddress'], status: 'active', mascara: ipsData['cidrIpLanCpe'] },
        { id: 1, bloco: 'IPV6 LAN', ip: ipsData['b2bLanIPV6CPENetworkAddress'], status: 'block', mascara: ipsData['cidrIpv6LanCpe'] },
        { id: 2, bloco: 'IPV4 WAN', ip: ipsData['b2bWanIPV4CPENetworkAddress'], status: 'active', mascara: ipsData['cidrIpWanCpe'] },
        { id: 3, bloco: 'IPV6 WAN', ip: ipsData['b2bWanIPV6CPENetworkAddress'], status: 'active', mascara: ipsData['cidrIpv6WanCpe'] },
        { id: 4, bloco: 'IP LOOPBACK', ip: ipsData['b2bLoopbackIPV4NetworkAddress'], status: 'active', mascara: ipsData['cidrIpLoopback'] },
      ]);
    }
  }, [dataFacility]);

  /**
   * Scroll to the notification
   */
  useEffect(() => {
    if (notificationRef.current) {
      notificationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [error, success]);

  /**
   * Request callback response from the config
   */
  useEffect(() => {
    if (resultId) {
      //Method to ping the Hpsd Config Callback
      const pingConfigStatus = async () => {
        try {
          const resp = await hpsdConfigCallbackRequest(resultId);
          if (resp.data && Object.keys(resp.data).length > 0) {
            // If the config is finished, clear the interval
            clearInterval(intervalId);
            if (resp.data.event.serviceOrder.serviceOrderItem) {
              const service = resp.data.event.serviceOrder.serviceOrderItem[0].service;
              const state = resp.data.event.serviceOrder.state;
              if (service.category === 'ManobraIPs.MNIP06') {
                setLoading(false);
                if (state === 'completed') {
                  setSuccess('Manobra realizada com sucesso.');
                } else if (state === 'held') {
                  setWarning(`Manobra em Held, entrar em contato com o HPSD para finalizar a ação. ${resp.data.event.serviceOrder.description ?? ''}`);
                } else {
                  setError(`Falha na manobra: \n${resp.data.event.serviceOrder.description ?? 'Verificar com o HPSD.'}`);
                }
              }
            } else {
              setError('Timeout não recebeu resposta do callback HPSD.');
            }
          }
        } catch (error) {
          handleError({ error, setError });
          clearInterval(intervalId);
          setLoading(false);
        }
      };

      // Set up a periodic ping every 5 seconds to check if the Config Task is already finished and get the result
      const intervalId = setInterval(async () => {
        // Check task status initially
        pingConfigStatus();
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [resultId]);

  const handleSubmitManobra = async () => {
    setResultId('');
    setError('');
    setSuccess('');
    setWarning('');
    let isValid = true;
    try {
      if (ipsSelected.length === 0) {
        setError('Selecione pelo menos um item');
        isValid = false;
      }
      if (!motive) {
        setError('Selecione um motivo');
        isValid = false;
      }
      const requestValues = ipsSelected.map((id) => {
        if (!ipTableRows[id].newMascara) {
          setError('Um ou mais itens selecionados não tem um valor de máscara');
          isValid = false;
        }
        return { bloco: ipTableRows[id].bloco, novaMascara: ipTableRows[id].newMascara };
      });

      if (isValid) {
        console.log('Realizar Manobra');
        setLoading(true);

        const blocoIps = {
          name: 'BLOCOS_IPS',
          value: requestValues
        };

        executeManobra(blocoIps);
      }
    } catch (error) {
      handleError({ error, setError });
      setLoading(false);
    }
  };

  const handleSelectClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = ipsSelected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(ipsSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(ipsSelected.slice(1));
    } else if (selectedIndex === ipsSelected.length - 1) {
      newSelected = newSelected.concat(ipsSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        ipsSelected.slice(0, selectedIndex),
        ipsSelected.slice(selectedIndex + 1),
      );
    }
    setIpsSelected(newSelected);
  };

  const handleIpInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowId?: number) => {
    const value = event.target.value;
    // Update the ipTableRows with the new value
    setIpTableRows(prevRows => {
      return prevRows.map(row => {
        if (row.id === rowId) {
          return { ...row, ip: value }; // Changing ip attribute
        }
        return row;
      });
    });
  };

  const handleMascaraInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowId?: number) => {
    const value = event.target.value;
    // Update the ipTableRows with the new value
    setIpTableRows(prevRows => {
      return prevRows.map(row => {
        if (row.id === rowId) {
          return { ...row, newMascara: value }; // Adding newMascara attribute
        }
        return row;
      });
    });
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ width: '100%', marginTop: 1 }} spacing={2}>
        <div ref={notificationRef}>
          {error && (
            <Alert severity="error" variant='filled' onClose={() => { setError(''); }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" variant='filled' onClose={() => { setSuccess(''); }}>
              {success}
            </Alert>
          )}
          {warning && (
            <Alert severity="warning" variant='filled' onClose={() => { setWarning(''); }}>
              {warning}
            </Alert>
          )}
        </div>
      </Stack>
      {geralCharacteristic && (
        <Box
          width="100%"
          marginTop={1}
        >
          <Card>
            <CardContent sx={{
              '& .MuiTextField-root': { m: 1 },
              display: 'flex',
              justifyContent: 'space-around'
            }}>
              <TextField
                label="Tipo Acesso"
                value={geralCharacteristic.TIPO_ACESSO}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label="Rede"
                value={geralCharacteristic.PLACE_RESERVED?.network_owner}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label="Serviço"
                value={geralCharacteristic.TIPO_SERVICO}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
              <TextField
                label="Razão Social"
                value={geralCharacteristic.RAZAO_SOCIAL}
                variant='standard'
                InputProps={{
                  readOnly: true
                }}
                fullWidth
              />
            </CardContent>
          </Card>
          {ipTableRows && (
            <TableContainer component={Paper} sx={{ marginTop: 1 }}>
              <Typography variant='h6' textAlign={'center'} sx={{ backgroundColor: '#333333', color: '#fff' }}>
                Configurações de IP
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Tipo de IPs</StyledTableCell>
                    <StyledTableCell align='center'>Bloco</StyledTableCell>
                    <StyledTableCell align='center'>Máscara</StyledTableCell>
                    <StyledTableCell align='center'>Status</StyledTableCell>
                    <StyledTableCell align='center'>Manobra</StyledTableCell>
                    <StyledTableCell align='center'>Nova Máscara</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ipTableRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    return (
                      <StyledTableRow
                        hover
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell>{row.bloco}</StyledTableCell>
                        <StyledTableCell align='center'>
                          <TextField
                            fullWidth
                            disabled={row.status !== ('active' || 'busy')}
                            variant='outlined'
                            value={row.ip}
                            onChange={(event) => handleIpInputChange(event, row.id)}
                            InputProps={{
                              inputComponent: row.bloco.includes('IPV6') ? '' as any : IPV4MaskInput as any
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align='center'>{row.mascara}</StyledTableCell>
                        <StyledTableCell align='center'>{row.status}</StyledTableCell>
                        <StyledTableCell align='center'>
                          <Checkbox
                            color='primary'
                            disabled={row.status !== ('active' || 'busy')}
                            checked={isItemSelected}
                            onClick={(event) => handleSelectClick(event, row.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <TextField
                            fullWidth
                            disabled={row.status !== ('active' || 'busy')}
                            variant='outlined'
                            value={row.newMascara ?? ''}
                            onChange={(event) => handleMascaraInputChange(event, row.id)}
                            InputProps={{
                              inputComponent: IpMaskMaskInput as any
                            }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Card className='card' sx={{ marginTop: 1 }}>
            <CardContent>
              <FormControl fullWidth margin='normal'>
                <InputLabel>Motivo</InputLabel>
                <Select
                  value={motive}
                  label="Motivo"
                  required
                  onChange={(e) => setMotive(e.target.value as string)}
                >
                  {motives.map((motive) => (
                    <MenuItem
                      key={motive}
                      value={motive}
                    >
                      {motive}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop={1}
          >
            <Button
              className='button'
              onClick={handleSubmitManobra}
              disabled={loading}
            >
              {loading ? `Manobrando...` : `Manobrar`}
            </Button>
          </Box>
        </Box>
      )}
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          {/* <CircularWithValueLabel maxSecondsTime={180} interval={5000} /> */}
          <CircularProgressIndeterminateWithLabel maxSecondsTime={600} descriptions={
            ['Processo em andamento...', 'Processo demorando mais que o normal...', 'Provável falha na consulta por timeout...']
          }
          />
        </Backdrop>
      )}
    </Box>
  );
};

export default ManobraIpDisplay;